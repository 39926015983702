
<div class="bot-alerts">
  <b-alert
    v-for="(alert, index) in alertStore.activeMessages"
    :key="index"
    variant="warning"
    dismissible
    :show="5"
    :value="!!alert.message"
    @dismissed="alertStore.removeAlert"
  >
    {{ alert.message }}
  </b-alert>
</div>
